<template>
<div class="mapview">
    <h1>SHOP</h1>
<div class="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d432.3133855713229!2d141.13088631448161!3d38.7746864719297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f891d2ca35794f9%3A0xf3cd250fede6bb18!2sJames%20Fifty%20COMPANY!5e0!3m2!1sja!2sjp!4v1649736581466!5m2!1sja!2sjp" width="300" height="300" style="border:0; filter:grayscale(1);" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <div class="mapInfo">
        <h2>James Fifty COMPANY</h2>
        <p>TEL 0066-9701-0596</p>
        <p>宮城県栗原市若柳川北塚ノ根22-2 KWGP内</p>
        <p>東北自動車道 若柳金成ICから車で約７分</p>
        <p>JR東北本線 石越駅からタクシーで約６分</p>
        <p>くりでんミュージアムさまとなり</p>
    </div>
</div>
</div>
</template>

<script>

</script>

<style scoped>
.mapview {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 10vh;
}
h1 , h2 {
    text-align: center;
    color: #8F7D64;
}
p {
    text-align: center;
}
.map {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.mapInfo {
    padding-left: 2vw;
}
</style>