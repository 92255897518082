<template>
      <div>
        <transition>
          <router-view />
        </transition>
      </div>
</template>



<style scoped>
h1{
  color: #8F7D64;
  text-align: center;
}
h2 {
  text-align: center;
  overflow-wrap: break-word;
}
p {
  width: 90%;
  margin: 0 auto;
  overflow-wrap: break-word;
  text-align: center;
}

@media screen and (min-width: 1025px) {
  .PC_view {
    width: 50vw;
    margin: auto;
  }

  .topimage {
    max-height: 70vh;
    object-fit: cover;
  }
}
.v-enter-active, .v-leave-active {
  transition: opacity .5s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}
</style>
