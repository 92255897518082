<template>
<div>
<div class="inventView">
        <div class="carImage c55">
            <router-link :to = "{ name: 'StockList', query:{param: '1955'} }">
            <div class="list">
                <h3><span>'55</span></h3>
                <p><span>BELAIR</span></p>
            </div>
            <p class="triangle"></p>
            </router-link>
        </div>
    <div class="carImage c56">
        <router-link :to = "{ name: 'StockList', query:{param: '1956'} }">
        <div class="list">
            <h3><span>'56</span></h3>
            <p><span>BELAIR</span></p>
        </div>
        <p class="triangle"></p>
        </router-link>
    </div>
    <div class="carImage c57">
        <router-link :to = "{ name: 'StockList', query:{param: '1957'} }">
        <div class="list">
            <h3><span>'57</span></h3>
            <p><span>BELAIR</span></p>
        </div>
        <p class="triangle"></p>
        </router-link>
    </div>
    <div class="carImage other">
        <router-link :to = "{ name: 'StockList', query:{param: 'other'} }">
        <div class="list">
            <h3><span>その他</span></h3>
            <p><span>OTHERS</span></p>
        </div>
        <p class="triangle"></p>
        </router-link>
    </div>
</div>
<div class="allLink">
    <router-link :to = "{ name: 'StockList', query:{param: 'all'} }">
        <div class="list">
            <h3><span>全ての車両を見る</span></h3>
        </div>
    </router-link>
</div>
</div>
</template>

<script>
</script>

<style scoped>
h3 span {
    font-size: 3rem;
    padding-right: 3vw;
    border-bottom: solid 1px;
}
.carImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.c55 {
    background-image: url('@/assets/55_SP.webp');
}
.c56 {
    background-image: url('@/assets/56_SP.webp');
}
.c57 {
    background-image: url('@/assets/57_SP.webp');
}

.other {
    background-image: url('@/assets/other_SP.webp');
}
.list *{
    margin: 0;
    width: 100%;
}

.list {
    width: 100%;
    align-self: flex-start;
    text-align: right;
}
.allLink .list {
    text-align: center;
}
.list p span {
    color: #8f7d64;
    border-bottom: #8f7d64 solid 1px;
    padding-right: 2vw;
}
.triangle {
  align-self: flex-end;
  width: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid #8f7d64;
  margin: auto;
  margin-bottom: 10px;
}
a {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.allLink h3 span {
    font-size: 1.5rem;
}

@media screen and (min-width: 1025px) {
    .inventView {
        display: flex;
        justify-content: center;
    }
    .carImage {
        width: 25%;
        height: 35vh;
        background-position: top;
    }
    .triangle {
        align-self: auto;
        margin: auto;
    }
    .list {
        text-align: center;
    }
    h3 span , .list p span{
        padding: 0;
    }
    .c55 {
        background-image: url('@/assets/55_PC.webp');
    }
    .c56 {
        background-image: url('@/assets/56_PC.webp');
    }
    .c57 {
        background-image: url('@/assets/57_PC.webp');
    }

    .other {
        background-image: url('@/assets/other_PC.webp');
    }

    .allLink {
        width: 15vw;
        border: solid 1px #fff;
        margin: 0 auto;
        margin-top: 5vh;
        padding-bottom: 10px;
    }
}
</style>