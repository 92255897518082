<template>
<div class="pure-container" data-effect="pure-effect-slide">
    <input type="checkbox" id="pure-toggle-right" class="pure-toggle" data-toggle="right">
    <label class="pure-toggle-label" for="pure-toggle-right" data-toggle-label="right">
        <span class="pure-toggle-icon"></span>
    </label>
    <nav class="pure-drawer" data-position="right">
        
          <ul>
            <li><router-link :to = "{ name: 'Home'}" @click="close_menu">James Fifty COMPANYとは</router-link></li>
            <li><router-link :to = "{ name: 'StockList', query:{param: '1955'} }" @click="close_menu">'55 BELAIR</router-link></li>
            <li><router-link :to = "{ name: 'StockList', query:{param: '1956'} }" @click="close_menu">'56 BELAIR</router-link></li>
            <li><router-link :to = "{ name: 'StockList', query:{param: '1957'} }" @click="close_menu">'57 BELAIR</router-link></li>
            <li><router-link :to = "{ name: 'StockList', query:{param: 'other'} }" @click="close_menu">その他</router-link></li>
            <li><router-link :to = "{ name: 'StockList', query:{param: 'all'} }" @click="close_menu">車両一覧</router-link></li>
          </ul>
        
    </nav>
    <div class="pure-pusher-container">
        <div class="pure-pusher">
          <h1><router-link :to = "{ name: 'Home'}" @click="close_menu">James Fifty COMPANY</router-link></h1>
        </div>
    </div>
    <label class="pure-overlay" for="pure-toggle-right" data-overlay="right"></label>
</div>

</template>

<script>
export default {
  name: 'HeadNav',
  setup() {
    const close_menu = function(){
      const elm = document.querySelectorAll('.pure-toggle[data-toggle="right"]');
      elm[0].checked=false;
    }
    return {
      close_menu
    }
  }
}
</script>

<style scoped>
h1 {
    color: #8F7D64;
    margin: 0;
    padding: 10px 3px;
    padding-left: 10px;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}
.pure-toggle-label {
  border: none;
  top: 10px;
  width: 35px;
  height: 35px;
}
.pure-toggle-label[data-toggle-label='right'] {
  right: 5px;
}
.pure-drawer[data-position='right'] {
  display: flex;
  align-items: center;
}
.pure-drawer {
  width: 60%;
  padding-left: 10%;
}
.pure-pusher {
  background-color: #222;
}
.pure-toggle-label .pure-toggle-icon,
.pure-toggle-label .pure-toggle-icon:before,
.pure-toggle-label .pure-toggle-icon:after ,
.pure-toggle-label:hover .pure-toggle-icon,
.pure-toggle-label:hover .pure-toggle-icon:before,
.pure-toggle-label:hover .pure-toggle-icon:after {
  background-color: #8F7D64;
}
@media screen and (min-width: 1025px) {
.pure-drawer {
  width: 20%;
  padding-left: 10%;
}
}
</style>