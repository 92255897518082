<template>
  <article>
    <section>
      <div id="top">
        <div class="topMainImg">
          <img src="@/assets/top.webp" class="topimage">
          <div class="topMess">
            <h1>The best life <br class="sp">with a American classic car</h1>
            <h2>アメリカンクラシックカーと共に<br class="sp">最高の人生を</h2>
          </div>
        </div>
        <div id="PageTop" class="PC_view topView">
          <p>
            1908年、米フォード社が<br>
            世界初のベルトコンベア式大量生産によって<br>
            起こしたモータリゼーション。<br>
            アメ車の代名詞とも言える、<br>
            唯一無二のV8サウンドに包まれて、<br>
            最高のステータスを感じてほしい。<br>
            James Fifty COMPANYは、<br>
            確かな技術と確かなクオリティで、<br>
            あなたの理想のアメリカンクラシックカーライフを実現します。<br>
          </p>
        </div>
      </div>
    </section>
    <hr class="cp_hr06" />
    <section>
      <div id="StockList">
        <StockList />
      </div>
    </section>
    <hr class="cp_hr06" />
    <section>
      <div id="AccessView">
        <AccessView />
      </div>
    </section>
  </article>
</template>

<script>
import StockList from '../components/StockList.vue'
import AccessView from '../components/AccessView.vue'
export default {
  components: { StockList , AccessView},
  mounted() {
    document.title = 'James Fifty COMPANY'
  }
}

</script>

<style scoped>
h1{
  color: #8F7D64;
  text-align: center;
}
h2 {
  text-align: center;
  overflow-wrap: break-word;
}
p {
  width: 90%;
  margin: 0 auto;
  overflow-wrap: break-word;
  text-align: center;
}

.topMainImg {
  overflow: hidden;
  position: relative;
}

.topMainImg img {
      width: 100vw;
      transform: scaleX(2) translateX(25vw);
      height: 50vh;
      transition: all 300ms 0s ease;
      -webkit-animation: 20s linear 0s infinite alternate pan_img;
      animation: 20s linear 0s infinite alternate pan_img;
}

.topMess {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 90vw;
}

.topMess h1 , .topMess h2 {
  margin: 0;
  text-shadow: 1px 1px 1px#000;
}

.topMess h1 {
  transform: scaleY(1.5);
}

.topMess h2 {
  color: #fff;
  margin-top: 5px;
}

.topView {
  padding: 5vh 0;
}

@-webkit-keyframes pan_img { 
  0% { transform: scaleX(2) translateX(25vw); } 
  5% { transform: scaleX(2) translateX(25vw); }
  95% { transform: scaleX(2) translateX(-25vw); }
  100% { transform: scaleX(2) translateX(-25vw); } 
}
@keyframes pan_img {
  0% { transform: scaleX(2) translateX(25vw); } 
  5% { transform: scaleX(2) translateX(25vw); }
  95% { transform: scaleX(2) translateX(-25vw); }
  100% { transform: scaleX(2) translateX(-25vw); }
}

@media screen and (min-width: 1025px) {
  .PC_view {
    width: 50vw;
    margin: auto;
  }

  .topimage {
    max-height: 75vh;
    object-fit: cover;
  }

  .topMainImg img {
      width: 100%;
      transform: unset;
      height: unset;
      height: unset;
      animation: unset;
}
.topMess {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* width: 90vw; */
}
}
</style>
