<template>
    <div>
        <div class="back">
            <p><span> &copy; 2018-2022 ROOTS</span></p>
        </div>
    </div>
</template>

<style scoped>
.back {
    background-image: url('@/assets/FBG.webp');
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 20vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.back p span{
    background-color: #fff;
    color: #000;
    text-align: center;
}
</style>