import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/JfcHome.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/StockList',
    name: 'StockList',
    component: () => import('../views/StockCar.vue')
  },
  {
    path: '/StockDetail',
    name: 'StockDetail',
    component: () => import('../views/DetailCar.vue')
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 }
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

export default router