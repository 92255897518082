import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import header from './components/HeadNav.vue'
import footer from './components/FooterNav.vue'
import VueGtag from "vue-gtag";

createApp(App).use(router).use(VueGtag, {
    config: { id: "UA-180658580-1" }
  }).mount('#main')
createApp(header).use(router).mount('#head')
createApp(footer).use(router).mount('#footer')


require('@/assets/scss/style.scss')
require('@/assets/js/pure-drawer-master/dist/css/pure-drawer.min.css')